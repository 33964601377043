import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { MAT_AUTOCOMPLETE_SCROLL_STRATEGY, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { SharedModule } from 'app/shared/shared.module';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { DevicePreviewModule } from '@modules/ucontrol/devices/preview/device-preview.module';
import { TagsModule } from '@modules/common/tags/tags.module';
import { CourierTrackingStatusComponent } from '@modules/common/courier-trackings/status/status.component';

@NgModule({
    declarations: [SearchComponent],
    imports: [
        RouterModule.forChild([]),
        MatAutocompleteModule,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        SharedModule,
        DevicePreviewModule,
        TagsModule,
        CourierTrackingStatusComponent
    ],
    exports: [SearchComponent],
    providers: [
        {
            provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => (): ScrollStrategy => overlay.scrollStrategies.block(),
            deps: [Overlay]
        }
    ]
})
export class SearchModule {}
