import { NgModule } from '@angular/core';
import { LayoutComponent } from 'app/layout/layout.component';
import { EmptyLayoutModule } from 'app/layout/layouts/empty/empty.module';
import { DenseLayoutModule } from 'app/layout/layouts/vertical/dense/dense.module';
import { SharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { ModernLayoutModule } from './layouts/horizontal/modern/modern.module';
import { EnterAsModule } from './common/enter-as/enter-as.module';
import { EntranceComponent } from './layouts/entrance/entrance.component';
import { BookLayoutModule } from './layouts/vertical/book/book.module';

const layoutModules = [
    // Empty
    EmptyLayoutModule,

    // Vertical navigation
    DenseLayoutModule,
    BookLayoutModule,

    // Horizontal navigation
    ModernLayoutModule
];

@NgModule({
    declarations: [LayoutComponent, EntranceComponent],
    imports: [SharedModule, RouterModule, LetDirective, PushPipe, EnterAsModule, ...layoutModules],
    exports: [LayoutComponent, ...layoutModules]
})
export class LayoutModule {}
