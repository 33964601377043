import { ActionReducerMap } from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

// reducers
import { coreReducer, IAppCore } from './app-core';
import { authReducer, IAuthState } from '@store/auth';
import { ISettingsState, settingsReducer } from '@store/settings';

export interface AppState {
    core: IAppCore;
    router: RouterReducerState;
    auth: IAuthState;
    settings: ISettingsState;
}

export const AppReducers: ActionReducerMap<AppState> = {
    core: coreReducer,
    router: routerReducer,
    auth: authReducer,
    settings: settingsReducer
};

export const AppActions = [];
