<tour-step-template>
    <ng-template let-step="step">
        <div class="flex flex-col rounded-md p-2" (click)="$event.stopPropagation()">
            <div class="flex space-x-4 justify-between items-center px-4 mb-2">
                <span class="text-lg leading-6 font-semibold">{{ step?.title }}</span>
                <mat-icon svgIcon="heroicons_outline:x" (click)="tourService.end()"></mat-icon>
            </div>

            <span class="max-w-200 px-4" [innerHTML]="step?.content"></span>

            <div class="flex space-x-4 justify-between items-center">
                <button mat-icon-button [disabled]="!tourService.hasPrev(step)" (click)="tourService.prev()">
                    <mat-icon svgIcon="heroicons_outline:chevron-left"></mat-icon>
                </button>
                <button mat-icon-button *ngIf="tourService.hasNext(step)" (click)="tourService.next()">
                    <mat-icon svgIcon="heroicons_outline:chevron-right"></mat-icon>
                </button>
                <button mat-icon-button (click)="tourService.end()" *ngIf="!tourService.hasNext(step)">
                    <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
                </button>
            </div>
        </div>
    </ng-template>
</tour-step-template>

<router-outlet></router-outlet>
