import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LetDirective, PushPipe } from '@ngrx/component';
import { RequestCreateComponent } from './create.component';
import { FuseDateRangeModule } from '@shared/components/date-range';
import { ModelSelectorModule } from '@shared/components/model-selector/model-selector.module';

@NgModule({
    imports: [SharedModule, ReactiveFormsModule, LetDirective, PushPipe, FuseDateRangeModule, ModelSelectorModule],
    providers: [],
    declarations: [RequestCreateComponent],
    exports: [RequestCreateComponent]
})
export class RequestsCreateModule {}
