import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppErrorHandler } from '@core/services/services/error-handler';
import { ProgressBarService } from '@core/services/services/progress-bar.service';

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(
        private errorHandler: AppErrorHandler,
        private _progressBarService: ProgressBarService
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this._progressBarService.setLoading(true, req.url);

        return next.handle(req).pipe(
            tap({
                next: event => {
                    if (event instanceof HttpResponse) {
                        this._progressBarService.setLoading(false, req.url);
                    }
                },
                error: err => {
                    if (err instanceof HttpErrorResponse) {
                        this._progressBarService.setLoading(false, req.url);
                        this.handleError<Array<string> | string>(err);
                    }

                    throw err;
                }
            })
        );
    }

    handleError<errorType>(error: Error | any): void {
        this.errorHandler.handleError<errorType>(error);
    }
}
