import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, Router, RouterModule, TitleStrategy } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { AuthModule } from './modules/auth/auth.module';
import { ErrorsModule } from './modules/errors/errors.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { EntranceModule } from './modules/common/entrance/entrance.module';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { PageTitleStrategy } from '@core/services/services/title.strategy';
import { TranslocoRootModule } from './transloco/transloco-root.module';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from '@env/environment';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';

const routerConfig: ExtraOptions = {
    // preloadingStrategy       : PreloadAllModules,
    scrollPositionRestoration: 'enabled'
};

const getErrorHandler = (): ErrorHandler => {
    if (environment.sentryUrl) {
        return Sentry.createErrorHandler();
    }

    return new ErrorHandler();
};

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse
        FuseModule,
        FuseConfigModule.forRoot(appConfig),

        // Core
        CoreModule,

        // Layout
        LayoutModule,

        // 3rd party modules
        MarkdownModule.forRoot({}),

        NgxPermissionsModule.forRoot(),

        AuthModule,
        ErrorsModule,
        EntranceModule,
        TourMatMenuModule.forRoot(),
        TranslocoRootModule,

        //Modules for guide
        MatIconModule,
        MatButtonModule
    ],
    providers: [
        {
            provide: ErrorHandler,
            useFactory: getErrorHandler
        },
        { provide: Sentry.TraceService, deps: [Router] },
        {
            provide: APP_INITIALIZER,
            useFactory: () => (): void => {},
            deps: environment.sentryUrl ? [Sentry.TraceService] : [],
            multi: true
        },
        { provide: TitleStrategy, useClass: PageTitleStrategy },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                subscriptSizing: 'dynamic'
            }
        },
        { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: { disabled: true } }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
