import { NgModule } from '@angular/core';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AppReducers, AppState } from './reducers';
import { environment } from '@env/environment';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AppEffectsModules } from '@store/effects';
import { NavigationSerializer } from '@store/router-store';
import { APP_DISPATCHERS } from '@store/dispatchers';
import { APP_SELECTORS } from '@store/selectors';

export { AppState } from './reducers';

export const localStorageSyncReducer = (reducer: ActionReducer<any>): ActionReducer<any> =>
    localStorageSync({
        keys: ['core', 'auth'],
        // keys: [
        //   {
        //     core: Object.keys(coreInitialState),
        //     auth: Object.keys(authInitialState).filter(value => value !== 'loggedIn'),
        //   }
        // ],
        rehydrate: true
    })(reducer);

export const debug =
    (reducer: ActionReducer<any>): ActionReducer<any> =>
    (state, action) =>
        reducer(state, action);

export const metaReducers: MetaReducer<AppState>[] = !environment.production
    ? [debug, localStorageSyncReducer]
    : [localStorageSyncReducer];

@NgModule({
    imports: [
        StoreModule.forRoot(AppReducers, { metaReducers }),
        StoreRouterConnectingModule.forRoot(),
        AppEffectsModules
    ],
    declarations: [],
    exports: [],
    providers: [
        ...APP_DISPATCHERS,
        ...APP_SELECTORS,
        { provide: RouterStateSerializer, useClass: NavigationSerializer }
    ]
})
export class CoreStoreModule {}

// Dev Tools, enable only if required
// imports: [ StoreDevtoolsModule.instrument({ maxAge: 25 })]
