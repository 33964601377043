import { ApiHttpService } from './services/api-http.service';
import { AuthGuard } from '@core/services/guards/auth.guard';
import { GuestGuard } from '@core/services/guards/guest.guard';
import { WebsocketService } from '@core/services/services/websocket.service';
import { ProgressBarService } from '@core/services/services/progress-bar.service';
import { SnackbarService } from '@core/services/services/snackbar.service';
import { AuthService } from '@core/services/resource/auth.service';
import { SecurityService } from '@core/services/resource/security.service';
import { NavigationService } from '@core/services/services/navigation.service';

export const APP_RESOURCE_SERVICES = [AuthService, SecurityService];

export const APP_SERVICES = [
    AuthGuard,
    GuestGuard,
    ApiHttpService,
    WebsocketService,
    ProgressBarService,
    SnackbarService,
    NavigationService,
    ...APP_RESOURCE_SERVICES
];
