import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared/shared.module';
import { RouterModule } from '@angular/router';
import { EntranceComponent } from './entrance.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EnterAsModule } from '../../../layout/common/enter-as/enter-as.module';

@NgModule({
    declarations: [EntranceComponent],
    imports: [BrowserAnimationsModule, RouterModule, CommonModule, SharedModule, LetDirective, PushPipe, EnterAsModule]
})
export class EntranceModule {}
