import { NgModule } from '@angular/core';
import { FuseDrawerModule } from '@fuse/components/drawer';
import { SharedModule } from 'app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EnterAsComponent } from './enter-as.component';
import { FuseAlertModule } from '../../../../@fuse/components/alert';
import { EnterAsSectionComponent } from './section/section.component';
import { EnterAsOrganisationComponent } from './organisation/organisation.component';
import { EnterAsSectionPartnerStateIndicatorComponent } from './section/partner-state-indicator/partner-state-indicator.component';

@NgModule({
    declarations: [
        EnterAsComponent,
        EnterAsSectionComponent,
        EnterAsOrganisationComponent,
        EnterAsSectionPartnerStateIndicatorComponent
    ],
    imports: [FuseDrawerModule, SharedModule, RouterModule, LetDirective, PushPipe, FuseAlertModule],
    exports: [EnterAsComponent, EnterAsSectionComponent, EnterAsOrganisationComponent]
})
export class EnterAsModule {}
