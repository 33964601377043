import { Component } from '@angular/core';
import { TourService } from 'ngx-ui-tour-md-menu';
import { AuthSelectors } from '@store/auth';
import { filter, take, withLatestFrom } from 'rxjs/operators';
import { environment } from '@env/environment';
import { OneSignal } from 'onesignal-ngx';
import { IUser } from '@models/user';
import * as Sentry from '@sentry/angular-ivy';
import { SettingsSelectors } from '@store/settings';
import { IOrganisation } from '@models/organisation';
import { LocalStorageService } from '@core/services/services/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    constructor(
        public readonly tourService: TourService,
        private authSelectors: AuthSelectors,
        private settingsSelectors: SettingsSelectors,
        private oneSignal: OneSignal,
        localStorage: LocalStorageService
    ) {
        localStorage.migrate();

        this.settingsSelectors.organisation$
            .pipe(
                filter(organization => !!organization),
                withLatestFrom(authSelectors.user$),
                take(1)
            )
            .subscribe(([organization, user]) => {
                this.configureSentry(user);
                this.configureWebPushNotifications(user, organization);
            });
    }

    private configureSentry(user: IUser): void {
        if (environment.sentryUrl) {
            Sentry.configureScope(scope => {
                scope.setUser({
                    username: user.name,
                    email: user.email
                });
            });
        }
    }

    private configureWebPushNotifications(user: IUser, organization: IOrganisation): void {
        if (environment.type === 'qa') {
            return;
        }

        const onesignalConnection = organization.connections.find(
            connection => connection.connectable_type === 'onesignal' && connection.status === 'active'
        );

        if (onesignalConnection) {
            this.oneSignal.setExternalUserId(user.id.toString());

            this.oneSignal.init({
                appId: onesignalConnection.connectable.app_id,
                safari_web_id: onesignalConnection.connectable.safari_web_id,
                allowLocalhostAsSecureOrigin: environment.type === 'development'
            });
        }
    }
}
