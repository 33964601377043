import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { Error404Component } from './404/error-404.component';
import { RouterModule } from '@angular/router';
import { Error401Component } from './401/error-401.component';

@NgModule({
    imports: [SharedModule, RouterModule],
    declarations: [Error404Component, Error401Component]
})
export class ErrorsModule {}
