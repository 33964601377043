import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { SearchModule } from 'app/layout/common/search/search.module';
import { ShortcutsModule } from 'app/layout/common/shortcuts/shortcuts.module';
import { UserMenuModule } from 'app/layout/common/user-menu/user-menu.module';
import { SharedModule } from 'app/shared/shared.module';
import { DenseLayoutComponent } from 'app/layout/layouts/vertical/dense/dense.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { TasksModule } from '../../../common/tasks/tasks.module';
import { NotificationsModule } from '../../../common/notifications/notifications.module';
import { RequestsCreateModule } from '../../../../modules/common/requests/create/create.module';
import { NavigationModule } from '../../../navigation';
import { LogoModule } from '../../../common/organisation-selector/logo.module';

@NgModule({
    declarations: [DenseLayoutComponent],
    exports: [DenseLayoutComponent],
    imports: [
        RouterModule,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatMenuModule,
        NavigationModule,
        NotificationsModule,
        TasksModule,
        SearchModule,
        ShortcutsModule,
        UserMenuModule,
        SharedModule,
        LetDirective,
        PushPipe,
        RequestsCreateModule,
        LogoModule
    ],
    providers: [provideHttpClient(withInterceptorsFromDi())]
})
export class DenseLayoutModule {}
