import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ThemeSwitcherComponent } from './theme-switcher.component';
import { MatIconModule } from '@angular/material/icon';
import { LetDirective, PushPipe } from '@ngrx/component';

@NgModule({
    declarations: [ThemeSwitcherComponent],
    imports: [MatSlideToggleModule, MatIconModule, LetDirective, PushPipe],
    exports: [ThemeSwitcherComponent]
})
export class ThemeSwitcherModule {}
