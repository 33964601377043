import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, take, tap } from 'rxjs/operators';
import { AuthService } from '@core/services/resource/auth.service';
import { AuthDispatchers } from './auth.dispatchers';
import * as AuthActions from './auth.actions';
import { AuthSelectors } from '@store/auth/auth.selectors';
import { AppCoreDispatchers } from '@store/app-core/app-core.dispatchers';

@Injectable()
export class AuthEffects {
    login$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.login),
            concatMap(action =>
                this.authService.login(action.credentials).pipe(
                    map(response => AuthActions.loginSuccess({ two_factor: response.two_factor })),
                    catchError(error => of(AuthActions.loginFailure({ error })))
                )
            )
        )
    );

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            concatMap(action =>
                this.authService.logout().pipe(
                    map(response => AuthActions.logoutSuccess()),
                    catchError(error => of(error))
                )
            )
        )
    );

    logoutSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.logoutSuccess),
                concatMap(action => this.router.navigate(['/auth/login']))
            ),
        { dispatch: false }
    );

    invalidateToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.invalidateToken),
            map(() => AuthActions.loginRedirect())
        )
    );

    loginSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginSuccess),
                tap(action => {
                    if (!action.two_factor) {
                        this.authDispatchers.loadUser();
                        this.navigateToStartPage();
                    } else {
                        this.authDispatchers.twoFactorAuthRequired();
                    }
                })
            ),
        { dispatch: false }
    );

    loginRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loginRedirect),
                tap(authed => {
                    // this.authService.removeToken();
                    this.router.navigate(['/auth/login']);
                })
            ),
        { dispatch: false }
    );

    loadUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loadUser),
            concatMap(action =>
                this.authService.loadUser().pipe(
                    map(user => AuthActions.loadUserSuccess({ user })),
                    catchError(error => of(AuthActions.loadUserFailure(error)))
                )
            )
        )
    );

    updateUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.updateUser),
            concatMap(action =>
                this.authService.updateProfile(action.data).pipe(
                    map(user => AuthActions.updateUserSuccess({ user })),
                    catchError(error => of(AuthActions.updateUserFailure(error)))
                )
            )
        )
    );

    loadUserFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.loadUserFailure),
            map(action => AuthActions.loginRedirect())
        )
    );

    loadUserSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.loadUserSuccess),
                tap(action => this.coreDispatchers.changeScheme(action.user.settings?.scheme || 'light', true))
            ),
        { dispatch: false }
    );

    twoFactorAuthRequired$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.twoFactorAuthRequired),
                tap(action => {
                    this.router.navigate(['/auth/2fa']);
                })
            ),
        { dispatch: false }
    );

    authBroadcast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.authBroadcast),
            concatMap(action =>
                this.authService.authorizePrivateBroadcast(action.channel).pipe(
                    map(response => AuthActions.authBroadcastSuccess({ auth: response.auth })),
                    catchError(error => of(AuthActions.authBroadcastFailure({ error })))
                )
            )
        )
    );

    authBroadcastSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.authBroadcastSuccess),
                map(response => response.auth)
            ),
        { dispatch: false }
    );

    authBroadcastFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.authBroadcastFailure),
            map(action => AuthActions.loginRedirect())
        )
    );

    twoFactorSubmit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.twoFactorSubmit),
            concatMap(action =>
                this.authService.twoFactorChallenge(action.data).pipe(
                    map(response => AuthActions.twoFactorSuccess()),
                    catchError(error => of(AuthActions.twoFactorFailure({ error })))
                )
            )
        )
    );

    disableGuide$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.disableGuides),
            concatMap(action =>
                this.authService
                    .disableGuides(action.names)
                    .pipe(
                        map(user =>
                            AuthActions.updateUserProperties({ properties: { active_guides: user.active_guides } })
                        )
                    )
            )
        )
    );

    twoFactorSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(AuthActions.twoFactorSuccess),
                tap(action => {
                    this.authDispatchers.loadUser();
                    this.navigateToStartPage();
                })
            ),
        { dispatch: false }
    );

    twoFactorFailure$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.twoFactorFailure),
            map(() => AuthActions.loginRedirect())
        )
    );

    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private router: Router,
        private authDispatchers: AuthDispatchers,
        private authSelectors: AuthSelectors,
        private coreDispatchers: AppCoreDispatchers
    ) {}

    navigateToStartPage(): void {
        this.authSelectors.user$
            .pipe(
                filter(u => !!u),
                take(1)
            )
            .subscribe(user => {
                this.router.navigate([this.authService.redirectUrl || '/entrance']);
            });
    }
}
