import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { LogoComponent } from './logo.component';
import { LetDirective, PushPipe } from '@ngrx/component';

@NgModule({
    declarations: [LogoComponent],
    imports: [RouterModule, MatButtonModule, MatIconModule, MatTooltipModule, SharedModule, LetDirective, PushPipe],
    exports: [LogoComponent]
})
export class LogoModule {}
