import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'app/shared/shared.module';
import { TasksComponent } from './tasks.component';
import { LetDirective, PushPipe } from '@ngrx/component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ModalDialogModule } from '@shared/components/modal-dialog/modal-dialog.module';

@NgModule({
    declarations: [TasksComponent],
    imports: [
        RouterModule,
        OverlayModule,
        PortalModule,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        SharedModule,
        LetDirective,
        PushPipe,
        MatProgressBarModule,
        ModalDialogModule
    ],
    exports: [TasksComponent]
})
export class TasksModule {}
